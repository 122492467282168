import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import GalleryComponent from '../components/Gallery'

const OurWorkPage = (props) => (
  <Layout>
    <Helmet>
      <title>Acuarios Irrigation Our Work</title>
      <meta name="description" content="Acuarios Irrigation Our Work Page" />
    </Helmet>
    <div id="main">
      <section id="two">
        <div className="inner" style={{ overflow: 'hidden' }}>
          <header
            className="major"
            style={{
              marginTop: '30px',
            }}
          >
            <h2>Our Work</h2>
          </header>
          <p>
            <GalleryComponent type="all" />
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default OurWorkPage
